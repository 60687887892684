<template>
  <div class="api-setting">
    <div class="header">
      <div class="style-title-setting-global">
        {{
          $i18n.locale === 'fr' ? 'Gestion API token' : 'API token Management'
        }}
      </div>
      <div v-if="getApiTokenLoading && !initLoading" class="loading">
        {{ $t('loading') }}
      </div>
      <div
        class="color-crm font-sz-12 font-text mr-3 bold-700"
        v-if="messageCopy"
      >
        {{ messageCopy }}
      </div>
      <v-btn class="btn-filter" dark color="#5C2DD3" @click="apiToken">
        {{
          $i18n.locale === 'fr'
            ? 'Générer un nouveau Token'
            : 'Generate new Token'
        }}
      </v-btn>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="content mt-3" v-else>
      <div class="mt-3">
        <v-text-field
          :label="
            $i18n.locale === 'fr'
              ? 'Votre token personnel API'
              : 'Your personal API token'
          "
          dense
          :persistent-placeholder="true"
          outlined
          color="#5C2DD3"
          item-color="#5C2DD3"
          disabled
          :value="getApiToken"
        >
          <template #append-outer>
            <v-icon @click="copyTokenApi(getApiToken)" :title="$t('copy')"
              >mdi-content-copy</v-icon
            >
          </template>
        </v-text-field>
      </div>
    </div>

    <v-dialog v-model="genereToken" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Générer un nouveau Token'
                : 'Generate new Token'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('genereToken')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="my-4">
            {{ message }}
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getApiTokenLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getApiTokenError" class="error-msg">
              <ul v-if="Array.isArray(getApiTokenError)">
                <li v-for="(e, index) in getApiTokenError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getApiTokenError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click="addToken"
            :loading="getApiTokenLoading"
            small
          >
            {{ $i18n.locale === 'fr' ? 'Oui' : 'Yes' }}
          </v-btn>
          <v-btn text @click="closeDialog('genereToken')" small>{{
            $i18n.locale === 'fr' ? 'Non' : 'No'
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      initLoading: true,
      message: null,
      genereToken: false,
      messageCopy: null
    }
  },

  methods: {
    ...mapActions(['generateApiToken', 'fetchApiToken']),
    closeDialog(ref) {
      this[ref] = false
      this.messageCopy = null
    },
    async addToken() {
      await this.generateApiToken()
      this.closeDialog('genereToken')
    },
    copyTokenApi(text) {
      const el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      el.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand('copy')
      document.body.removeChild(el)
      //   this.$bvToast.toast(text, {
      //     title: 'API Token copié',
      //     autoHideDelay: 2000,
      //     toaster: 'b-toaster-bottom-right',
      //     variant: 'success',
      //     solid: true
      //   })
      this.messageCopy =
        this.$i18n.locale === 'fr' ? 'API Token copié' : 'API Token copied'

      setTimeout(() => {
        this.messageCopy = null
      }, 800)
    },
    apiToken() {
      if (!this.getApiToken) {
        this.message =
          this.$i18n.locale === 'fr'
            ? 'Générer un nouveau token, êtes-vous sûr ?'
            : 'Are you sure you can generate a new token?'
      } else {
        this.message =
          this.$i18n.locale === 'fr'
            ? 'Générer un nouveau token effacera le token existant , êtes-vous sûr ?'
            : 'Generating a new token will delete the existing one, are you sure?'
      }
      this.genereToken = true
    }
  },
  computed: {
    ...mapGetters(['getApiToken', 'getApiTokenLoading', 'getApiTokenError'])
  },
  async mounted() {
    await this.fetchApiToken()
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.api-setting {
  .header {
    display: flex;
    align-items: center;
  }
  .content {
    margin-top: 60px !important;
  }
}
</style>
